<script setup lang="ts">
import Carousel from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/Carousel.vue';
import OptionSelect from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/OptionSelect.vue';
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import { formatPrice } from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';
import { objectToCssVariablesDeprecated } from '@zyro-inc/site-modules/utils/objectToCssVariablesDeprecated';
import {
	EcommerceProductListScrollBehaviour,
	ImageHoverEffect,
	ImageRatioOption,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';
import {
	SITE_PRODUCT_SELECTION,
	SITE_PRODUCT_SELECTION_TYPE_LOWEST,
	MAX_PRODUCTS_IN_CART,
	DEFAULT_EMPTY_PRODUCT_VALUE,
	ECOMERCE_PRODUCTS_FREE_TYPES,
} from '@zyro-inc/site-modules/constants/ecommerce'; import {
	EcommerceProductType,
	EcommerceProduct,
	EcommerceProductVariantQuantity,
	EcommerceProductOptionValue,
	EcommerceProductVariant,
	EcommerceProductAdditionalInfo,
	EcommerceQuantifiedItem,
	EcommerceCartUpdatePayload,
	EcommerceRegion,
} from '@zyro-inc/site-modules/types';

import {
	getFormattedBookingDuration,
	isProductPriceRangeShown,
	getVariantQuantity,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import ProductSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductSkeletonLoader.vue';
import QuantityPicker from '@zyro-inc/site-modules/components/ecommerce/-partials/QuantityPicker.vue';
import AdditionalInfoSections from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/AdditionalInfoSections.vue';
import {
	computed,
	watch,
	ref,
	onMounted,
} from 'vue';
import {
	DATA_ATTRIBUTE_ANIMATION_ROLE,
	DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import CustomField from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/CustomField.vue';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce/common';
import ExpressCheckoutElement from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ExpressCheckoutElement.vue';

const MAX_STOCK_AMOUNT_TO_SHOW = 5;
const DEFAULT_PICKER_VALUE = 1;
const CUSTOM_ATTRIBUTES = {
	[DATA_ATTRIBUTE_ANIMATION_ROLE]: DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
};

const PRODUCT_TYPES_WITHOUT_QUANTITY_PICKER = [
	EcommerceProductType.BOOKING,
	EcommerceProductType.DIGITAL,
];

interface Props {
	blockId: string;
	productData: EcommerceProduct;
	relatedProducts?: EcommerceProduct[];
	relatedProductsTitle?: string;
	blockStyle?: Record<string, string>,
	textColorVars?: Record<string, string>,
	blockButtonText?: string,
	blockButtonStyle?: Record<string, string>,
	blockButtonType?: string,
	blockButtonBorderWidth?: number,
	navigationArrowsColor?: string,
	navigationThumbnailArrowsColor?: string,
	galleryPlacement?: string,
	imageRatio?: ImageRatioOption,
	imageBorderRadius?: string,
	isLoading?: boolean,
	isCheckoutLoading?: boolean,
	canAddToCart?: (product: EcommerceProduct, productVariantId: string) => boolean,
	isEager?: boolean,
	translations: Record<string, string>,
	quantifiedCartItemsList?: EcommerceQuantifiedItem[],
	isQuantityPickerEnabled: boolean,
	legacyProductPages?: Record<string, {[key: string]: any}> | {},
	isQuickPreview?: boolean,
	isCartVisible: boolean,
	siteId: string,
	shoppingCartItems?: Array<EcommerceProduct> | [],
	variantsQuantity: Array<EcommerceProductVariantQuantity>,
	currentPageType?: string,
	isMobileView?: boolean,
	isExpressCheckoutEnabled?: boolean,
	cartId?: string,
	successUrl?: string,
	updateCartData?:(payload: EcommerceCartUpdatePayload) => Promise<void>,
	stripePublicKey?: string,
	stripeAccountId?: string,
	storeId: string,
	isInEditor?: boolean,
	regions?: EcommerceRegion[],
	expressCheckoutText?: string,
	relatedProductsPageCount?: number,
	relatedProductsCurrentPage: number,
	isRelatedProductsLoading: boolean,
	isRelatedProductLinkDisabled?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	imageRatio: ImageRatioOption.COVER,
	imageBorderRadius: '0%',
	currentPageType: 'default',
	blockStyle: () => ({}),
	textColorVars: () => ({}),
	blockButtonStyle: () => ({}),
	blockButtonText: '',
	blockButtonType: 'primary',
	blockButtonBorderWidth: 0,
	navigationArrowsColor: '',
	navigationThumbnailArrowsColor: '',
	galleryPlacement: '',
	canAddToCart: () => true,
	shoppingCartItems: () => [],
	isLoading: false,
	isCheckoutLoading: false,
	isEager: false,
	quantifiedCartItemsList: () => [],
	legacyProductPages: () => ({}),
	isQuickPreview: false,
});

const emit = defineEmits<{
	'buy-button-click': [EcommerceProduct[]],
	'click-more': [],
	'image-click': [number],
	'open-cart': [],
	'set-cart-data': [any],
	'express-checkout-click': [],
	'get-regions': [],
	'create-cart': [EcommerceProduct?],
	'related-product-page-changed': [number],
	'related-product-click': [EcommerceProduct],
	'add-related-product-to-bag': [EcommerceProduct],
}>();

const selectedQuantity = ref(1);
const variantImage = ref('');
const isLimitReached = ref(false);
const isButtonClicked = ref(false);
const selectedVariant = ref<EcommerceProduct>(DEFAULT_EMPTY_PRODUCT_VALUE as EcommerceProduct);
const isAddToBagEnabled = ref(true);
const blockProductRef = ref<HTMLElement | null>(null);

const isBlockInProductPage = computed(() => isProductPage(props.currentPageType));
const buttonBackgroundColor = computed(() => ({
	normal: props.blockButtonStyle[`grid-button-${props.blockButtonType}-background-color`],
	hover: props.blockButtonStyle[`grid-button-${props.blockButtonType}-background-color-hover`],
}));
const buttonBorderColor = computed(() => ({
	hover: props.blockButtonStyle[`grid-button-${props.blockButtonType}-border-color-hover`],
	normal: props.blockButtonStyle[`grid-button-${props.blockButtonType}-border-color`],
}));
const textAlign = computed(() => props.blockStyle?.textAlign);
const productData = computed(() => props.productData);
const uniqueOptionSelections = computed<Array<{[key: number]: EcommerceProductOptionValue}>>(() => {
	if (!productData.value.options.length) {
		return [];
	}

	return productData.value.options.map((option) => ({
		...option.values
			.filter((selection, index, self) => self.findIndex((selectionTwo) => selectionTwo.value === selection.value) === index),
	}));
});
const selectedValueVariant = computed(() => selectedVariant.value?.variants[0]);
const isPurchasable = computed(() => props.productData.purchasable === undefined || props.productData.purchasable);

const selectedOption = (index: number) => {
	const optionValue = selectedValueVariant.value?.options.find(
		(option) => option.option_id === uniqueOptionSelections.value[index][0].option_id,
	);

	return Object.values(uniqueOptionSelections.value[index]).find((option) => optionValue?.value === option.value)?.id;
};

const priceData = computed(() => (selectedVariant.value.id
	? selectedValueVariant.value?.prices[0]
	: productData.value?.variants[0].prices[0]));

const quantityInCart = computed(() => props.quantifiedCartItemsList
	.find((item) => item.product.variants[0].id === selectedValueVariant.value?.id)?.quantity || 0);
const totalQuantitySelected = computed(() => {
	if (!props.isCartVisible) {
		return selectedQuantity.value;
	}

	return selectedQuantity.value + quantityInCart.value;
});
const isStockAvailable = computed(() => {
	if (selectedValueVariant.value?.manage_inventory) {
		return totalQuantitySelected.value < getVariantQuantity({
			variantsQuantity: props.variantsQuantity,
			variantId: selectedValueVariant.value?.id,
		});
	}

	return true;
});
const computedStyles = computed(() => ({
	...objectToCssVariablesDeprecated({
		...props.textColorVars,
		...props.blockButtonStyle,
	}),
}));
const isPriceShown = computed(() => !productData.value?.options.length || (productData.value?.options.length && selectedVariant.value));
const isProductTypeBooking = computed(() => productData.value?.type.value === EcommerceProductType.BOOKING);
const isProductTypeGiftCard = computed(() => productData.value?.type.value === EcommerceProductType.GIFT_CARD);
const isFreeTypeProduct = computed(() => ECOMERCE_PRODUCTS_FREE_TYPES.includes(productData.value?.type.value));
const isAddToCartDisabled = computed(
	() => !props.canAddToCart(props.productData, selectedVariant.value?.variants[0].id),
);
const isFreeTypeProductFree = computed(() => isFreeTypeProduct.value && !priceData.value?.amount);
const isFreeDigitalProduct = computed(() => productData.value?.type.value === EcommerceProductType.DIGITAL && !priceData.value?.amount);
const buttonText = computed(() => {
	if (props.blockButtonText) {
		return props.blockButtonText;
	}

	if (isProductTypeBooking.value) {
		return props.translations?.bookNow || 'Book now';
	}

	if (isFreeDigitalProduct.value && !props.isCartVisible) {
		return props.translations?.download || 'Download';
	}

	return props.translations?.addToBag || 'Add to bag';
});
const formattedPrice = computed(() => (isFreeTypeProductFree.value
	? props.translations?.free || 'Free'
	: formatPrice({
		amount: priceData.value?.amount,
		currency: priceData.value?.currency,
	})));
const location = computed(() => productData.value?.variants[0].booking_event?.location);
const formattedBookingDuration = computed(() => getFormattedBookingDuration(productData.value, props.translations));
const isOutOfStock = computed(() => selectedValueVariant.value?.manage_inventory && getVariantQuantity({
	variantsQuantity: props.variantsQuantity,
	variantId: selectedValueVariant.value?.id,
}) === 0);
const isStockInfoShown = computed(() => !!selectedValueVariant.value?.manage_inventory);
const stockInfoText = computed(() => {
	const quantity = getVariantQuantity({
		variantsQuantity: props.variantsQuantity,
		variantId: selectedValueVariant.value?.id,
	});

	if (isOutOfStock.value || quantityInCart.value === quantity) {
		return props.translations.outOfStock;
	}

	const prefix = quantity <= MAX_STOCK_AMOUNT_TO_SHOW ? quantity : `${MAX_STOCK_AMOUNT_TO_SHOW}+`;

	return `${prefix} ${props.translations.inStock} `;
});
const productAdditionalInfo = computed<EcommerceProductAdditionalInfo[]>(
	() => [...(productData.value?.additional_info || [])].sort((a, b) => a.order - b.order),
);
const isDescriptionVisible = computed(() => productData.value?.description && !props.isQuickPreview);
const isAdditionalInfoVisible = computed(() => productAdditionalInfo.value && !props.isQuickPreview);
const isDescriptionShownOnMobileWithAdditionalSections = computed(() => productAdditionalInfo.value.length && props.isMobileView);
const productImages = computed(() => productData.value?.images || productData.value?.media || []);
const customFields = computed(() => productData.value?.custom_fields || []);
const isQuantityPickerShown = computed(() => props.isQuantityPickerEnabled && !isFreeTypeProductFree.value);
const isQuantityPickerVisible = computed(() => !(PRODUCT_TYPES_WITHOUT_QUANTITY_PICKER.includes(productData.value?.type.value))
	&& isQuantityPickerShown.value
	&& isPurchasable.value);
const isPhysical = computed(() => productData.value?.type.value === EcommerceProductType.PHYSICAL);
const productPageUrl = computed(() => `${window.location.origin}/${productData.value.seo_settings.slug}`);

const getProductWithSelectedVariant = (variant: EcommerceProductVariant) => ({
	...productData.value,
	variants: [variant],
});
const getInitiallySelectedVariant = (): EcommerceProduct => {
	if (!productData.value) {
		return DEFAULT_EMPTY_PRODUCT_VALUE as EcommerceProduct;
	}

	if (productData.value[SITE_PRODUCT_SELECTION] === SITE_PRODUCT_SELECTION_TYPE_LOWEST) {
		const firstLowestPriceVariant = productData.value.variants.reduce((acc, curr) => {
			const accPrice = acc.prices[0]?.sale_amount || acc.prices[0]?.amount;
			const currPrice = curr.prices[0]?.sale_amount || curr.prices[0]?.amount;

			return accPrice <= currPrice ? acc : curr;
		});

		const uniqueOptionValues: Array<EcommerceProductOptionValue> = uniqueOptionSelections.value.map(
			(optionGroup: {[key: number]: EcommerceProductOptionValue}) => Object.values(optionGroup).find(
				(opt) => firstLowestPriceVariant.options.some((opt2) => opt2.value === opt.value),
			) || {} as EcommerceProductOptionValue,
		);

		const preselectedVariant: EcommerceProductVariant = {
			...firstLowestPriceVariant,
			options: [...uniqueOptionValues],
		};

		return getProductWithSelectedVariant(preselectedVariant);
	}

	// this preselects the matching variant by the default options (uniqueOptionSelections) inside option selects
	const preselectedVariant = productData.value.variants
		.find((variant) => variant.options
			.every((option) => uniqueOptionSelections.value
				.some((selection) => selection[0].value === option.value)));

	return getProductWithSelectedVariant(preselectedVariant || productData.value.variants[0]);
};

const handleButtonClick = () => {
	isButtonClicked.value = true;

	if (!isAddToBagEnabled.value) {
		return;
	}

	const productForShoppingCart = new Array(selectedQuantity.value).fill(selectedVariant.value);

	const isSelectedProductTypeDigital = productForShoppingCart[0].type.value === EcommerceProductType.DIGITAL;

	if (isSelectedProductTypeDigital) {
		const isProductAlreadyInCart = props.shoppingCartItems.some((item) => item.id === productForShoppingCart[0].id);

		if (isProductAlreadyInCart) {
			emit('open-cart');

			return;
		}
	}

	selectedQuantity.value = DEFAULT_PICKER_VALUE;

	emit('buy-button-click', productForShoppingCart);
};

const handleExpressCheckoutButtonClick = () => {
	isButtonClicked.value = true;

	emit('express-checkout-click');
};

const handleVariantOptionChange = (id: string) => {
	selectedQuantity.value = DEFAULT_PICKER_VALUE;

	const optionSelection: EcommerceProductOptionValue = productData.value.options.map(
		(option) => option.values.find((optionValue) => optionValue.id === id),
	).find((item) => item) || {} as EcommerceProductOptionValue;

	const remainingVariantOptions = selectedValueVariant.value?.options
		.filter((option) => option.option_id !== optionSelection.option_id);

	const allVariantOptions = [
		...remainingVariantOptions,
		optionSelection,
	];

	// find correct variant with the same option values
	const matchedVariant = productData.value.variants.find((variant) => variant.options
		.every((option) => allVariantOptions
			.some((value) => value.value === option.value && value.option_id === option.option_id)));

	if (matchedVariant) {
		selectedVariant.value = getProductWithSelectedVariant(matchedVariant);
	}
};

const handleQuantityChange = (value: number) => {
	const isQuantityTracked = selectedValueVariant.value?.manage_inventory;

	isLimitReached.value = (props.shoppingCartItems.length + value) >= MAX_PRODUCTS_IN_CART;

	if (isQuantityTracked) {
		const maxQuantity = getVariantQuantity({
			variantsQuantity: props.variantsQuantity,
			variantId: selectedValueVariant.value?.id,
		});

		if ((quantityInCart.value + value) > maxQuantity || isLimitReached.value) {
			// limit is the smaller value - either available stock amount or available cart amount
			const limit = Math.min(maxQuantity - quantityInCart.value, MAX_PRODUCTS_IN_CART - props.shoppingCartItems.length);

			selectedQuantity.value = limit;

			return;
		}
	} else if (isLimitReached.value) {
		selectedQuantity.value = MAX_PRODUCTS_IN_CART - props.shoppingCartItems.length;

		return;
	}

	if (value <= 0) {
		selectedQuantity.value = DEFAULT_PICKER_VALUE;

		return;
	}

	selectedQuantity.value = value;
};

const handleCustomFieldUpdate = (value: {message: string, errors: number}, editedFieldIndex: number) => {
	isAddToBagEnabled.value = value.errors === 0;

	if (!isAddToBagEnabled.value) {
		return;
	}

	selectedVariant.value = {
		...selectedVariant.value,
		custom_fields: customFields.value.map((field, index) => {
			if (index !== editedFieldIndex) {
				return field;
			}

			return {
				...field,
				value: value.message,
			};
		}),
	};

	if (props.isExpressCheckoutEnabled && !props.cartId) {
		emit('create-cart', selectedVariant.value);
	}
};

const setInitialProductData = () => {
	selectedVariant.value = getInitiallySelectedVariant();
	isAddToBagEnabled.value = !props.productData?.custom_fields?.some((field) => field.is_required && !field.value);
};

// If cart is not created yet - create, unless custom fields are required
const handleCreateCart = () => {
	if (!isAddToBagEnabled.value) {
		return;
	}

	emit('create-cart');
};

onMounted(() => {
	if (productData.value) {
		setInitialProductData();
	}
});

watch(productData, (newValue, oldValue) => {
	if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
		setInitialProductData();
	}
});
watch(selectedVariant, (newValue, oldValue) => {
	// should return first image of the product if the product does not have lowest price site product selection
	if (oldValue?.id === -1 && !newValue?.site_product_selection) {
		return;
	}

	if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
		if (newValue?.variants[0].image_url) {
			variantImage.value = newValue.variants[0].image_url;
		}
	}
});

const handleProductClick = (product: EcommerceProduct) => {
	blockProductRef.value?.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	});
	emit('related-product-click', product);
};

watch(() => props.shoppingCartItems, (newValue) => {
	const itemCount = newValue?.length || 0;

	// Since there is no 'this' in Vue 3 setup, we access the ref properties directly.
	selectedQuantity.value = DEFAULT_PICKER_VALUE;
	isLimitReached.value = (itemCount + selectedQuantity.value) >= MAX_PRODUCTS_IN_CART;
}, {
	immediate: true,
});
</script>

<template>
	<div
		:id="blockId"
		v-bind="CUSTOM_ATTRIBUTES"
		ref="blockProductRef"
		class="block-product-wrapper"
	>
		<ProductSkeletonLoader
			v-if="isLoading"
			:image-border-radius="imageBorderRadius"
			:text-align="textAlign"
		/>
		<div
			v-else
			class="block-product"
			:class="{
				'block-product--centered': textAlign === 'center',
				'block-product--in-preview': isQuickPreview
			}"
			:style="computedStyles"
		>
			<div class="block-product__main-info">
				<div class="block-product__image-content-wrapper">
					<Carousel
						:media-items="productImages"
						:product-title="productData.title"
						:arrows-color="navigationArrowsColor"
						:navigation-thumbnail-arrows-color="navigationThumbnailArrowsColor"
						:gallery-placement="galleryPlacement"
						:image-ratio="imageRatio"
						:image-border-radius="imageBorderRadius"
						:is-eager="isEager"
						:site-id="siteId"
						:variant-image="variantImage"
						:is-quick-preview="isQuickPreview"
						:is-variant-image-preselected="isProductPriceRangeShown(productData)"
						@image-click="$emit('image-click', $event)"
					/>
					<p
						v-if="isDescriptionVisible && productAdditionalInfo.length && !isMobileView"
						class="block-product__description block-product__description--under-image"
						v-html="productData.description"
					/>
				</div>
				<div class="block-product__content-wrapper">
					<div class="block-product__price-data-wrapper">
						<component
							:is="isBlockInProductPage ? 'h1' : 'h2'"
							v-qa="'builder-product-section-title'"
							class="block-product__title"
						>
							{{ productData.title }}
						</component>
						<component
							:is="isBlockInProductPage ? 'h2' : 'h3'"
							v-if="!isQuickPreview && productData.subtitle"
							class="block-product__subtitle"
						>
							{{ productData.subtitle }}
						</component>
						<p
							v-if="Object.keys(priceData || {})?.length"
							class="block-product__price-wrapper"
							:class="{ 'block-product__price-wrapper--with-duration': isProductTypeBooking }"
						>
							<span
								v-if="isPriceShown"
								class="block-product__price body-large"
								:class="{ 'block-product__price--sale': priceData.sale_amount }"
							>
								{{ formattedPrice }}
							</span>
							<span class="block-product__additional-info">
								<span
									v-if="priceData.sale_amount && isPriceShown"
									class="block-product__price body-large"
								>
									{{ formatPrice({
										amount: priceData.sale_amount,
										currency: priceData.currency
									}) }}
								</span>
								<span
									v-if="isProductTypeBooking"
									class="block-product__duration body-large"
								>
									{{ formattedBookingDuration }}
								</span>
							</span>
						</p>
						<p
							v-if="isProductTypeBooking"
							class="block-product__location"
						>
							{{ location }}
						</p>
						<div
							v-if="productData.options.length"
							class="block-product__input-field-wrapper"
						>
							<OptionSelect
								v-for="(option, index) in productData.options"
								:key="`option-${index}`"
								:value="selectedOption(index)"
								:options="uniqueOptionSelections[index]"
								:title="isProductTypeGiftCard ? translations.amount : option.title"
								class="block-product__input-field"
								:class="{ 'block-product__input-field--centered' : textAlign === 'center' }"
								label-key="value"
								@set-value="handleVariantOptionChange($event)"
							/>
						</div>
						<div
							v-if="customFields.length"
							class="block-product__input-field-wrapper"
						>
							<CustomField
								v-for="(field, index) in customFields"
								:key="`custom-field-${index}`"
								class="block-product__input-field"
								:class="{ 'block-product__input-field--centered' : textAlign === 'center' }"
								:custom-field="field"
								:translations="translations"
								:is-button-clicked="isButtonClicked"
								@update:model-value="handleCustomFieldUpdate($event, index)"
							/>
						</div>
						<div
							v-if="isQuantityPickerVisible"
							class="block-product__quantity-wrapper"
							:class="{ 'block-product__quantity-wrapper--disabled' : isOutOfStock || isAddToCartDisabled }"
						>
							<QuantityPicker
								qa-selector="productpage"
								:quantity="selectedQuantity"
								:is-limit-reached="isLimitReached"
								:is-stock-available="isStockAvailable"
								@quantity-change="handleQuantityChange"
							/>
							<p
								v-if="isStockInfoShown"
								class="block-product__stock-text"
							>
								{{ stockInfoText }}
							</p>
						</div>
					</div>
					<div
						v-if="isPurchasable"
						class="block-product__button-wrapper"
					>
						<GridButton
							v-qa="`productsection-btn-addtobag`"
							:type="blockButtonType"
							:content="buttonText"
							class="block-product__button"
							:class="[
								`block-product__button--${blockButtonType}`,
								{ 'block-product__button--fixed-width': isExpressCheckoutEnabled }
							]"
							:is-loading="isCheckoutLoading"
							tag-name="button"
							:disabled="isAddToCartDisabled"
							:border-width="blockButtonBorderWidth"
							:border-color="buttonBorderColor.normal"
							:border-color-hover="buttonBorderColor.hover"
							:background-color="buttonBackgroundColor.normal"
							:background-color-hover="buttonBackgroundColor.hover"
							@click="handleButtonClick"
						/>
						<ExpressCheckoutElement
							v-if="isExpressCheckoutEnabled"
							:price-data="priceData"
							:is-physical="isPhysical"
							:products="[productData]"
							:update-cart-data="updateCartData"
							:success-url="successUrl"
							:cart-id="cartId"
							:stripe-public-key="stripePublicKey"
							:stripe-account-id="stripeAccountId"
							:store-id="storeId"
							:is-in-editor="isInEditor"
							:is-add-to-bag-enabled="isAddToBagEnabled"
							:regions="regions"
							:translations="translations"
							:above-text="expressCheckoutText"
							@set-cart-data="$emit('set-cart-data', $event)"
							@get-regions="$emit('get-regions')"
							@create-cart="handleCreateCart"
							@button-click="handleExpressCheckoutButtonClick"
						/>
					</div>
					<p
						v-if="isDescriptionVisible && (isDescriptionShownOnMobileWithAdditionalSections || !productAdditionalInfo.length)"
						v-qa="'builder-product-section-description'"
						class="block-product__description"
						v-html="productData.description"
					/>
					<AdditionalInfoSections
						v-if="isAdditionalInfoVisible"
						:data="productAdditionalInfo"
						:product-description="productData.description"
					/>
					<a
						v-else-if="isQuickPreview"
						:href="productPageUrl"
						class="block-product__link body-small"
					>
						{{ translations.moreDetails }}
					</a>
				</div>
			</div>
			<div
				v-if="relatedProducts?.length"
				class="block-product__related-products"
			>
				<BlockEcommerceProductList
					:block-id="blockId"
					:block-style="{
						...blockStyle,
						imageBorderRadius
					}"
					:text-color-vars="textColorVars"
					:is-product-list-shown="true"
					:products-per-page="4"
					:column-count="isMobileView ? 1 : 4"
					:page-count="relatedProductsPageCount ?? 1"
					:total-product-count="relatedProducts?.length ?? 0"
					:current-page="relatedProductsCurrentPage"
					:is-button-enabled="true"
					:button-display="'always'"
					:button-text="buttonText"
					:button-style="blockButtonStyle"
					:button-type="blockButtonType"
					:button-border-width="blockButtonBorderWidth"
					:is-loading="isRelatedProductsLoading"
					:is-categories-loaded="true"
					:is-list-centered="false"
					:ribbon-style="{}"
					:products="relatedProducts ?? []"
					:translations="translations"
					:image-ratio="imageRatio"
					:image-hover-effect="ImageHoverEffect.NO_EFFECT"
					:is-eager="false"
					:site-id="siteId"
					:variants-quantity="variantsQuantity"
					:product-sorting="{ enabled: false }"
					:product-search="{
						isEnabled: false,
						placeholderText: '',
						noResultsText: ''
					}"
					:background-color="''"
					:is-category-list-enabled="false"
					:is-full-width="true"
					:is-total-product-count-shown="false"
					:column-gap="24"
					:row-gap="24"
					:is-button-full-width="true"
					:is-mobile-view="isMobileView"
					:scroll-behaviour="EcommerceProductListScrollBehaviour.PAGINATION"
					:is-cart-visible="false"
					:is-dynamic-product-page-enabled="true"
					:is-whats-app-enabled="false"
					:is-scroll-to-top-enabled="false"
					:skip-pagination-scroll-to-top="true"
					:category-title="relatedProductsTitle"
					:remove-paddings="true"
					:is-product-list-item-link-disabled="isRelatedProductLinkDisabled"
					@button-click="(product) => emit('add-related-product-to-bag', product)"
					@page-changed="(page) => emit('related-product-page-changed', page)"
					@product-click="handleProductClick"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@import "@zyro-inc/site-modules/components/blocks/ecommerce/-partials/shared";
@include font-style("h3", ".block-product__title", ".block-product");
@include font-style("h5", ".block-product__subtitle", ".block-product");
@include font-style("body", "p,.block-product__input-field-wrapper", ".block-product");
@include font-style("body-large", ".body-large", ".block-product");
@include font-style("body-small", ".body-small", ".block-product");

.block-product-wrapper {
	z-index: $z-index-site-engine-block-grid;
	padding: var(--block-padding);
}

.block-product {
	$this: &;

	flex-direction: column;
	justify-content: center;
	width: 100%;
	max-width: var(--content-width);
	margin: 0 auto;
	text-align: left;

	&--centered {
		text-align: center;

		#{$this}__content-wrapper,
		#{$this}__price-data-wrapper,
		#{$this}__button-wrapper {
			align-items: center;
			text-align: center;
		}
	}

	&--in-preview {
		min-height: 500px;
		max-height: 700px;

		#{$this}__content-wrapper {
			justify-content: flex-start;
		}

		#{$this}__price-data-wrapper {
			justify-content: flex-start;
			overflow: auto;
		}
	}

	&__related-products {
		margin-top: 80px;
	}

	&__stock-text {
		display: flex;
		align-items: center;
		margin-left: 24px;
	}

	&__image-content-wrapper {
		width: 100%;
	}

	&__main-info {
		display: flex;
		width: 100%;
	}

	&__content-wrapper,
	&__price-data-wrapper {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		justify-content: flex-start;
		width: 100%;
	}

	&__content-wrapper {
		max-width: 624px;
		padding-left: 80px;
		word-break: break-word;
	}

	&__button-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		margin-top: 32px;
	}

	&__button {
		position: relative;
		display: flex;
		align-items: center;
		height: var(--button-height);

		&--primary {
			margin: calc(-1 * var(--grid-button-primary-border-null, var(--grid-button-primary-border-width, 0)));

			&:focus,
			&:hover {
				margin: calc(-1 * var(--grid-button-primary-border-null-hover, var(--grid-button-primary-border-width-hover, 0)));
			}
		}

		&--secondary {
			margin: calc(-1 * var(--grid-button-secondary-border-null, var(--grid-button-secondary-border-width, 0)));

			&:focus,
			&:hover {
				margin:
					calc(
						-1 * var(--grid-button-secondary-border-null-hover, var(--grid-button-secondary-border-width-hover, 0))
					);
			}
		}

		&--fixed-width {
			&#{&} {
				width: 100%;
				max-width: 340px; // same as for OptionSelect.vue
				justify-content: center;
			}
		}
	}

	&__title {
		margin-bottom: 8px;
	}

	&__subtitle {
		margin-bottom: 16px;
	}

	&__price-wrapper {
		display: flex;
		flex-wrap: wrap;

		&--with-duration {
			margin-bottom: 8px;
		}
	}

	&__additional-info {
		display: flex;
	}

	&__price {
		margin-bottom: 0;

		&--sale {
			margin-right: 8px;
			opacity: 0.4;

			&#{&}#{&} {
				text-decoration: line-through;
			}
		}
	}

	&__duration {
		&::before {
			margin: 0 8px;
			content: "|";
		}
	}

	&__location,
	&__notice {
		opacity: 0.6;
	}

	&__input-field-wrapper {
		width: 100%;
		margin-top: 32px;
	}

	&__input-field {
		&:not(:last-child) {
			margin-bottom: 24px;
		}

		&--centered {
			margin-right: auto;
			margin-left: auto;
		}
	}

	&__quantity-wrapper {
		display: flex;
		margin-top: 32px;

		&--disabled {
			pointer-events: none;
			filter: opacity(0.4);
		}
	}

	&__notice {
		margin-top: 8px;
		margin-bottom: 32px;
		font-size: 14px;
	}

	&__link {
		margin-top: 32px;

		&#{&} {
			text-decoration: underline;
		}
	}

	&__description {
		width: 100%;
		margin-top: 56px;
		word-break: break-word;
		white-space: pre-line;

		@include description-style;

		&--under-image {
			max-width: var(--max-width-description, 600px); // defined in shared scss
		}
	}
}

@include site-engine-mobile {
	.block-product-wrapper {
		padding: var(--m-block-padding);
		width: 100%;
	}

	.block-product {
		flex-direction: column;
		width: 100%;

		&__main-info {
			flex-direction: column;
		}

		&--centered {
			align-items: center;
		}

		&__content-wrapper {
			max-width: unset;
			padding: 32px 0 0;
		}
	}
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
